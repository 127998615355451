import React from 'react';
import { CatalogSettings, CatalogSettingsProps } from 'components/catalogSettings/CatalogSettings';
import { ThemeProvider } from 'themeProvider/ThemeProvider';
import { ContextualHelpProvider } from 'contextualHelp/contextualHelpContext';
import "./catalogSettingsApp.scss";

interface IBaseProps {
    baseUrl: string,
    width: number,
    height: number,
    hideNews: boolean,
    itemContextList: Array<IClientItem>,
    theme: string
}

interface CatalogSettingsAndThemesProps extends CatalogSettingsProps {
    theme: string,
    hiddens: Array<string>,
    showMaterialCatalog: boolean,
    enableContextualHelp: boolean
}

//=============================================================================
function setPropsFromURL(baseProps?: IBaseProps): CatalogSettingsAndThemesProps {
    let queryParams: URLSearchParams = new URLSearchParams(window.location.search),
        baseUrl: string = baseProps?.baseUrl || window.location.origin + window.location.pathname,       
        theme: string = baseProps?.theme || queryParams.get('theme') || 'orange-theme';    
    
    return {
        baseUrl: baseUrl,
        theme: theme,        
        locale: queryParams.get('locale') ?? 'en-US',
        token: queryParams.get('t') ?? '',
        hiddens: JSON.parse(queryParams.get('hiddens') || '[]'),
        showMaterialCatalog: queryParams.get('showMaterialCatalog') === 'true',
        enableContextualHelp: queryParams.get('enableContextualHelp') === 'true',
    };
}

//=============================================================================
export default function CatalogSettingsApp(baseProps?: IBaseProps) {
    let props: CatalogSettingsAndThemesProps = setPropsFromURL(baseProps);
    let theme = props.theme;
    
    if (theme.indexOf('-theme') === -1) {
        theme = theme + '-theme';
    }

    return (
        <ThemeProvider theme={theme}>
             <ContextualHelpProvider>
                <CatalogSettings {...Object.assign({}, props, { theme })} />
            </ContextualHelpProvider>
        </ThemeProvider>
    )
}