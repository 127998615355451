import React, { useState, useEffect, Fragment, useCallback, MutableRefObject, CSSProperties } from 'react';
import { DeleteIcon, Search2Icon } from 'icons/';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import './filterInput.scss';

type FilterInputProps = {
    inputRef: MutableRefObject<HTMLInputElement | null>,
    containerClassName?: string
    searchLabel?: string,
    resetLabel?: string,
    headerLabel?: string
    
    filterValue: string,
    dataTip?: string,
    dataFor?: string,
    sideMargin?: string,
    width?: string,
    debounce?: boolean,
    debounceDelay?: number,
    searchIcon?: boolean,
    minLenght?: number,
    stopDebounce?: boolean,
    previousSearchOpened?: boolean,
    autoFocus?: boolean,
    inputPaddingLeft?: string,
    tabIndex?: number,
    onChange(search: string): void,
    onKeyDown?(): void,
    onFocus?(): void,
    onBlur?(event: React.FocusEvent<HTMLInputElement>): void,
    onChangeFilter?(search: string): void,
    onKeyPress?(key: string): void,
    onClick?(): void,
    onMouseEnter?(): void;
}

export function FilterInput(props: FilterInputProps) {
    const debounceTime: number = props.debounceDelay || 1000,
        containerClassName: string = props.containerClassName || 'filter-input__container',
        marginStyle: CSSProperties = props.sideMargin ? { margin: props.sideMargin } : {},
        widthStyle: CSSProperties = props.width ? { width: props.width } : {},
        inputLeftPadding: CSSProperties = props.inputPaddingLeft ? { padding: '0 0 0 ' + props.inputPaddingLeft} : {},
        [filterValue, setFilterValue] = useState<string>(props.filterValue),
        [debounceEnabled, setDebounceEnabled] = useState<boolean>(props.debounce || false),

        handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
            debounce.cancel();
            const newValue: string = ev.target.value;            
            const removing: boolean = newValue.length < filterValue.length;

            setFilterValue(newValue);
            if (newValue.length > (props.minLenght || 3) || removing) {
                if (debounceEnabled) {                    
                    debounce(newValue);
                } else if (newValue === ''){
                    props.onChange('');                    
                } else {
                    if (props.onChangeFilter) props.onChangeFilter(newValue); //filtering previous search
                }
            }
        },
        handleResetClick = () => {
            setFilterValue('');
            props.onChange('');
        },
        handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
            const focusItem: HTMLCollectionOf<Element> | null = document.getElementsByClassName('search-item_previous__key-block focus');

            if (ev.key === 'Enter' && props.previousSearchOpened === true && focusItem && focusItem[0]) {
                let focusItem: HTMLCollectionOf<Element> | null = document.getElementsByClassName('search-item_previous__key-block focus');

                if (focusItem && focusItem[0]) {
                    let selectedKey: string | null = focusItem[0].textContent;

                    if (selectedKey) {
                        setFilterValue(selectedKey);
                        debounce.cancel();
                        props.onChange(selectedKey);
                    }
                }

            } else if (ev.key === 'Enter') {
                ev.stopPropagation();
                ev.preventDefault();
                props.onChange(filterValue);

                if (props.onKeyDown && filterValue.toLocaleLowerCase() === props.filterValue.toLocaleLowerCase()) {
                    props.onKeyDown();
                }
            } else if (ev.key === 'Tab') {
                props.onChange(filterValue);

                if (props.onKeyDown && filterValue.toLocaleLowerCase() === props.filterValue.toLocaleLowerCase()) {
                    props.onKeyDown();
                }
            
            } else {
                if (ev.key === 'ArrowDown' && props.onKeyPress) {
                    props.onKeyPress('ArrowDown');
                } else if (ev.key === 'ArrowUp' && props.onKeyPress) {
                    props.onKeyPress('ArrowUp');
                } else if (ev.key === 'ArrowRight' && props.onKeyPress) {
                    props.onKeyPress('ArrowRight');
                } else if (ev.key === 'ArrowLeft' && props.onKeyPress) {
                    props.onKeyPress('ArrowLeft');
                } 

            }
        },
        handleOnFocus = () => {
            if (props.onClick) {
                props.onClick();
            }

            if (props.onFocus) {
                props.onFocus();
            }
        },
        handleOnMouseEnter = () => {
            if (props.onMouseEnter) {
                props.onMouseEnter();
            }
        },
        handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
            if (props.onBlur) {
                props.onBlur(event);
            }
        },
        handleSearchClick = () => {
            props.onChange(filterValue);
        },
        debounce = useCallback(
            _.debounce((searchVal: string) => {
                props.onChange(searchVal);
            }, debounceTime),
            [props.onChange]
        );

    useEffect(() => {
        setFilterValue(props.filterValue);
    }, [props.filterValue]);
    
    return (
        <Fragment>
            <div className={`${containerClassName}  `}
                style={{ ...marginStyle, ...widthStyle }}                
            >
                {props.headerLabel &&
                    <div className={`filter-input__header  `}>{props.headerLabel}</div>
                }
                <input style={inputLeftPadding}
                    ref={props.inputRef}
                    className={`filter-input__input ${filterValue !== '' ? 'has-value': ''} `}
                    type='text'
                    placeholder={props.searchLabel}
                    value={filterValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    tabIndex={props.tabIndex}
                    //onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    autoFocus={props.autoFocus !== undefined ? props.autoFocus : true}
                    onClick={handleOnFocus}
                    onMouseEnter={handleOnMouseEnter}
                />
                {filterValue !== '' &&
                    <button className={`filter-input__reset-btn  `}
                        onClick={handleResetClick}
                        data-tooltip-content={props.resetLabel}
                        data-tooltip-id={props.dataFor || undefined}
                        data-tooltip-delay-show={1500}                        
                        data-tooltip-place='bottom'
                        data-tooltip-delay-hide={100}  
                        tabIndex={(props.tabIndex || 0 + 1)}               
                    >
                        <DeleteIcon width={16} height={16} />
                    </button>
                }

                {props.searchIcon !== false &&
                    <button className={`filter-input__search-btn  `}
                        onClick={handleSearchClick}
                        data-tooltip-id={props.dataFor || undefined}
                        data-tooltip-content={props.dataTip || undefined}
                        data-tooltip-delay-show={1500}                        
                        data-tooltip-place='bottom'   
                        tabIndex={-1}
                    >
                        <Search2Icon width={16} height={16} />
                    </button>
                }
            </div>
        </Fragment>
    );
}