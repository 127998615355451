import { ExplorerRoutes } from "../components/catalog-explorer/useNavigation";
import { authoringHubState, StateModule } from "./authoringHubState";

export type State = {
    items: Record<string, IClientItem>;
};

export enum TestHubStateEvent {
    ItemContextListUpdate = "item-context-list-update",
}

class TestHubState implements StateModule<State> {
    public state: State = {
        items: {},
    };

    async getItemContext(id: string) {
        if (!this.state.items[id]) {
            const resp = await CiCAPI.content.getItemVariant(id);
            if (resp && resp.result) {
                const variant = resp.result;
                this.state.items[id] = this._toItemCtx(variant);
            }
        }

        return this.state.items[id];
    }

    refreshItem(id: string) {
        if(this.state.items[id]) {
            delete this.state.items[id];
            this.selectItems([id]);
        }

    }

    selectItems(ids: string[]) {
        const promises = ids
            .filter((id) => id !== ExplorerRoutes.NEW_ENTITY)
            .map((id) => this.getItemContext(id));

        Object.values(this.state.items).forEach((item) => {
            item.instanceExtras!.selected = false;
        });

        Promise.all(promises).then((items) => {
            const selectedItems = items.filter((item) => item);
            selectedItems.forEach((itemCtx) => {
                itemCtx.instanceExtras!.selected = true;
            });
            authoringHubState.notifyChange({
                type: TestHubStateEvent.ItemContextListUpdate,
                itemContextList: selectedItems,
            });
        });
    }

    async applyFeatureOption(
        featureOption: IFeatureOption,
        feature: V2.IFeature,
        itemCtxList: IClientItem[]
    ) {
        const items = itemCtxList || Object.values(this.state.items);
        items.forEach(async (item) => {
            const id = item.itemId;
            if (id) {
                const featureState: IFeatureState = {
                    featureId: feature.id,
                    optionId: featureOption.id,
                };

                if (featureOption.value !== undefined) {
                    featureState.value = featureOption.value as number;
                }

                if (feature.path) {
                    featureState.subItemScope = feature.path;
                }

                const resp = await CiCAPI.content.getItemVariant(
                    id,
                    item.configurationState,
                    {
                        optionSelections: [featureState],
                    }
                );

                if (resp?.result) {
                    this.state.items[id] = this._toItemCtx(
                        resp.result as IItemVariant,
                        item.instanceId,
                        { selected: true }
                    );
                }

                this._notifyChange();
            }
        });
    }

    private _notifyChange() {
        authoringHubState.notifyChange({
            type: TestHubStateEvent.ItemContextListUpdate,
            itemContextList: Object.values(this.state.items),
        });
    }

    private _toItemCtx(
        itemVariant: IItemVariant,
        instanceId?: string,
        extras: IClientItem["instanceExtras"] = {}
    ): IClientItem {
        return {
            ...itemVariant,
            itemId: itemVariant.id,
            instanceId: instanceId || itemVariant.code + Date.now(),
            instanceExtras: extras,
        };
    }

    serialize() {
        return this.state;
    }
}

export const testHubState = authoringHubState.registerModule(
    "testhub",
    new TestHubState()
) as TestHubState;
