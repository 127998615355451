import {
    IChange,
    IFlatChange,
    Operation,
    applyChangeset,
    diff,
} from "json-diff-ts";
import { DiffReport, EntityType } from "../..";
import { AuthoringStateMgr, EntitiesState, State } from "./AuthoringStateMgr";
import { Entity, EntityKey } from "./AuthoringStateMgr.types";
import { areAllValuesNullOrUndefined } from "../../utils/CommonUtils";

export type PendingChange = {
    type: EntityType;
    current?: Entity;
    original?: Entity;
    new?: boolean;
    removed?: boolean;
    targetCode?: string;
};

export class PendingChangesMgr {
    private authoringMgr: AuthoringStateMgr;
    private state: State;

    constructor(authoringMgr: AuthoringStateMgr) {
        this.authoringMgr = authoringMgr;
        this.state = this.authoringMgr.state;
    }

    public updateDiffReport(
        catalogVersionId: string,
        type: EntityType,
        code: string
    ) {
        const state =
            this.state.entities[catalogVersionId][
                type.toLowerCase() as EntityKey
            ];
        const report = diff(state.original[code], state.current[code]);
        if (report.length) {
            state.diffReport[code] = report;
        } else {
            delete state.diffReport[code];
        }
    }

    public getChangedEntities(catalogVersionId: string, type: EntityType) {
        const diffReport = this.getDiffReport(
            catalogVersionId,
            type
        ) as DiffReport;

        const changedEntities: {
            added: string[];
            modified: string[];
            deleted: string[];
            invalid: string[];
        } = {
            added: [],
            modified: [],
            deleted: [],
            invalid: [],
        };

        if (!diffReport) {
            return changedEntities;
        }

        Object.keys(diffReport).forEach((entityCode) => {
            const entityReport = diffReport[entityCode];
            if (this._isEntityEdited(entityReport, Operation.ADD, "$root")) {
                changedEntities.added.push(entityCode);
            } else if (
                this._isEntityEdited(entityReport, Operation.REMOVE, entityCode)
            ) {
                changedEntities.deleted.push(entityCode);
            } else if (
                this._isEntityEdited(entityReport, Operation.UPDATE) ||
                this._isEntityEdited(entityReport, Operation.ADD) ||
                this._isEntityEdited(entityReport, Operation.REMOVE)
            ) {
                changedEntities.modified.push(entityCode);
            }
            if (
                this.authoringMgr.entities.getEntityError(
                    catalogVersionId,
                    type,
                    entityCode
                )
            ) {
                changedEntities.invalid.push(entityCode);
            }
        });

        return changedEntities;
    }

    public getAllPendingChanges(catalogVersionId: string) {
        const catalogState = this.state.entities[catalogVersionId];
        const changes: PendingChange[] = [];

        if (catalogState) {
            Object.values(EntityType).forEach((type) => {
                const entitiesState =
                    catalogState[type.toLowerCase() as EntityKey];
                changes.push(
                    ...Object.values(
                        Object.keys(entitiesState.diffReport).map((code) =>
                            this.getPendingChange(
                                code,
                                entitiesState.diffReport,
                                entitiesState,
                                type as EntityType
                            )
                        )
                    )
                );
            });
        }

        return changes;
    }

    public getPendingChanges(
        catalogVersionId: string,
        type: EntityType,
        code?: string
    ): PendingChange[] {
        this.authoringMgr.catalogs.openCatalog(catalogVersionId);

        const entityKey = type?.toLowerCase() as EntityKey;
        const entitiesState = this.state.entities[catalogVersionId][entityKey];
        const report = entitiesState.diffReport;

        if (code) {
            return [this.getPendingChange(code, report, entitiesState, type)];
        } else {
            const codes = Object.keys(report);
            return codes.map((code) =>
                this.getPendingChange(code, report, entitiesState, type)
            );
        }
    }

    public getPendingChange(
        code: string,
        report: DiffReport,
        entitiesState: EntitiesState<Entity>,
        type: EntityType
    ): PendingChange {
        const change: PendingChange = {
            original: entitiesState.original[code],
            current: entitiesState.current[code],
            type,
        };

        if (
            report[code]?.some(
                (r) => r.type === Operation.REMOVE && r.key === code
            )
        ) {
            delete change.current;
            change.removed = true;
        } else if (
            report[code]?.some(
                (r) =>
                    (r.key === "$root" && r.type === Operation.ADD) ||
                    r.key === "code"
            )
        ) {
            delete change.original;
            change.new = true;
        }

        return change;
    }

    public applyEntityDiff(
        entity: Entity,
        report: DiffReport,
        original?: Entity,
        ignoreRemoveOperations?: boolean
    ) {
        if (!original) {
            return entity;
        }

        let diffs: (IChange | null)[] = diff(original, entity);
        const deleteChange = diffs.find((d) => d?.key.includes("_DELETE_"));

        if (
            deleteChange &&
            deleteChange.value &&
            deleteChange.value.match(/true/i)
        ) {
            report[entity.code] = [
                { key: entity.code, type: Operation.REMOVE },
            ];
            return null;
        }

        if (ignoreRemoveOperations) {
            for (let i = 0; i < diffs.length; i++) {
                nullifyChanges(diffs, i);
            }
        }

        diffs = diffs.filter((d) => d);

        return applyChangeset(
            JSON.parse(JSON.stringify(original)),
            diffs as IFlatChange[]
        );
    }

    public markAsNew(catalogId: string, type: EntityType, code: string) {
        const state =
            this.state.entities[catalogId][type.toLowerCase() as EntityKey];

        state.original[code] = {} as Entity;
        state.diffReport[code] = [
            {
                key: "$root",
                type: Operation.ADD,
            },
        ];
    }

    public getDiffReport(
        catalogVersionId: string,
        type: EntityType,
        code?: string
    ) {
        if (
            !this.state.entities[catalogVersionId] ||
            !type ||
            !catalogVersionId
        )
            return null;

        const report =
            this.state.entities[catalogVersionId][
                type.toLowerCase() as EntityKey
            ]?.diffReport;

        if (code && report) {
            return report[code];
        } else {
            return report;
        }
    }

    private _isEntityEdited(
        report: DiffReport[""],
        operationType: Operation,
        value?: string
    ) {
        return report.some(
            (r) => r.type === operationType && (!value || r.key === value)
        );
    }
}

function nullifyChanges(changes: (IChange | null)[], index: number): void {
    const change = changes[index];

    if (!change) {
        return;
    }

    if (Array.isArray(change.changes)) {
        for (let i = 0; i < change.changes.length; i++) {
            nullifyChanges(change.changes, i);
        }

        change.changes = change.changes.filter((c: IChange) => c);
    } else if (
        change.type === Operation.REMOVE ||
        areAllValuesNullOrUndefined(change.changes ?? change.value)
    ) {
        changes[index] = null;
    }
}
