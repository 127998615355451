type ObjectRecordValues = string | number | boolean | Array<string>;
type ObjectRecord = Record<string, ObjectRecordValues>;
// ----------------------------------------------------------------------------
const
    NUMBER_CODE_OFFSET: number = 48,
    UC_ALPHA_CODE_OFFSET: number = 65,
    LC_ALPHA_CODE_OFFSET: number = 97,
    STARTS_WITH_NUMBER_RE: RegExp = /^\d+\./;

let g_nSeqId: number = 0;

export const
    ID_DELIMITERS: string = ".",
    GET_CODE_FROM_ID_RE: RegExp = new RegExp("(^\\d+?)\\" + ID_DELIMITERS + "(.*)", "gi");

// ----------------------------------------------------------------------------
export class CommonUtils {
    // ------------------------------------------------------------------------
    /** This method generates a [RFC4122](https://www.ietf.org/rfc/rfc4122.txt) v4 UUID.
     * @example '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
     */
    static genGUID(): string {
        let uuid: string;

        let crypt = globalThis.crypto;
        if (!crypt || !crypt.randomUUID) {
            // @ts-ignore
            crypt = globalThis.nodeRequire('crypto');
        }

        if (!crypt || !crypt.randomUUID) {
            // to prevent crashes at runtime depending on the environment, ie: node / electron / etc..
            // @ts-ignore
            crypt = {
                randomUUID: () => `${Math.round(Math.random() * 100000000).toString()}-${Math.round(Math.random() * 10000).toString()}-${Math.round(Math.random() * 10000).toString()}-${Math.round(Math.random() * 10000)}-${Math.round(Math.random() * 100000000).toString()}`
            }
        }

        uuid = crypt.randomUUID();

        return uuid;
    }

    // ------------------------------------------------------------------------
    static genNextSequence(): number {
        return ++g_nSeqId;
    }

    // ------------------------------------------------------------------------
    static genUUID(size: number = 8): string {
        let i: number,
            code: number,
            chunks: Array<string> = [];

        for (i = 0; i < size; i++) {
            code = Math.round(Math.random() * 59);

            if (code < 10) { // 0 - 9
                code = NUMBER_CODE_OFFSET + code;
            } else if (code < 34) { // A - Z
                code -= 9;
                code = UC_ALPHA_CODE_OFFSET + code;
            } else { // a - z
                code -= (9 + 25);
                code = LC_ALPHA_CODE_OFFSET + code;
            }

            chunks.push(String.fromCharCode(code));
        }

        return chunks.join('');
    }

    // extracts catalog version id and code from entity id
    //=========================================================================
    static splitEntityId(entityId: string): [string | undefined, string] {
        let catalogVersionId: string | undefined,
            code: string;

        if (entityId.includes(".") && !entityId.includes(":")) {
            let splitId: Array<string> = entityId.split(".");
            catalogVersionId = splitId.shift();
            code = splitId.join(".");
        } else {
            code = entityId;
        }

        return [catalogVersionId, code];
    }

    // ------------------------------------------------------------------------
    static getCodeFromId(entityId: string): string {
        GET_CODE_FROM_ID_RE.lastIndex = 0;
        return entityId?.replace(GET_CODE_FROM_ID_RE, '$2');
    }

    // ------------------------------------------------------------------------
    static getCatalogVersionIdFromEntityId(entityId: string): string {
        GET_CODE_FROM_ID_RE.lastIndex = 0;
        return entityId?.replace(GET_CODE_FROM_ID_RE, '$1') || "";
    }

    // ------------------------------------------------------------------------
    static getResolvedIdUsingEntityId(entityId: string, entityCode: string): string {
        let catalogVersionId: string = this.getCatalogVersionIdFromEntityId(entityId);
        return this.getResolvedIdFromCode(catalogVersionId, entityCode);
    }

    // ------------------------------------------------------------------------
    static getResolvedIdFromCode(catalogId: string | number, entityCode: string): string {
        let id: string;

        if (entityCode && STARTS_WITH_NUMBER_RE.test(entityCode)) {
            id = entityCode;
        } else {
            id = [catalogId, entityCode].join(ID_DELIMITERS);
        }

        return id;
    }

    // ------------------------------------------------------------------------
    static getFlatRefCodes(refCodes: IReferenceCodes | undefined): { [key: string]: string } {
        let flatRefCodes: { [key: string]: string } = {};

        if (refCodes) {
            if (refCodes.ean) {
                flatRefCodes["ean"] = refCodes.ean;
            }

            if (refCodes.sku) {
                flatRefCodes["sku"] = refCodes.sku;
            }

            if (refCodes.manufCode) {
                flatRefCodes["manufCode"] = refCodes.manufCode;
            }

            if (refCodes?.others) {
                for (let key in refCodes.others) {
                    flatRefCodes[key] = refCodes.others[key];
                }
            }
        }

        return flatRefCodes;
    }

    // ------------------------------------------------------------------------
    static hasMatchingObjectEntries(source: ObjectRecord, toMatch?: ObjectRecord): boolean {
        let isValidMatch: boolean = false;

        if (toMatch) {
            isValidMatch = Object.entries(source)
                .every(([key, val]: [string, ObjectRecordValues]) => {
                    let loop: boolean = true;

                    if (toMatch[key] !== val) {
                        loop = false;
                    }
                    return loop;
                });
        }

        return isValidMatch;
    }

    // ------------------------------------------------------------------------
    static matchesAll(source: Array<string>, toMatch?: Array<string>): boolean {
        let isValidMatch: boolean = false;

        if (toMatch) {
            isValidMatch = source.every((tag: string) => toMatch.includes(tag));
        }

        return isValidMatch;
    }

    // ------------------------------------------------------------------------
    static sizeOf(parent_data: object, size: number, objectRefs: Array<object>): number {
        let prop: any;
        for (prop in parent_data) {
            let value: any = parent_data[prop as keyof object];
            if (prop === "catalog" || prop === "parentItem" || prop === "_catalogItem") {
                continue;
            }

            size += prop.length;

            if (typeof value === 'boolean') {
                size += 4;
            } else if (typeof value === 'string') {
                size += (value as string).length * 2;
            } else if (typeof value === 'number') {
                size += 8;
            } else {
                if (!objectRefs.includes(value)) { // prevent circular dependencies
                    objectRefs.push(value);
                    size += this.sizeOf(value, 0, objectRefs);
                }
            }
        }

        return size;
    }

    // ------------------------------------------------------------------------
    // make a list of known references to avoid duplicating information
    static roughSizeOfObject(object: object): number {
        let size: number = 0;
        let prop: any;
        let objectRefs: Array<object> = [];

        for (prop in object) {
            size += prop.length;
            if (prop === "catalog" || prop === "parentItem") {
                objectRefs.push(object[prop as keyof object]);
            } else {
                size += this.sizeOf(object[prop as keyof object], 0, objectRefs);
            }
        }
        return size;
    }

    // ------------------------------------------------------------------------
    static pruneUndefined(object: Record<string, unknown | undefined>): Record<string, unknown> {
        Object
            .entries(object)
            .forEach(([key, val]: [string, unknown | undefined]) => {
                if (val === undefined) {
                    delete object[key];
                }
            });

        return object;
    }
}
