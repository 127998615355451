import path from "path";


export class ObjectUtils {

    //=========================================================================
    static generateClientItemKey(clientItem: IClientItem): string {
        let { instanceId, itemId, configurationState, automations }: IClientItem = clientItem;

        return [
            instanceId,
            itemId,
            JSON.stringify(configurationState),
            JSON.stringify(automations)
        ].join(":");
    }

    static getObjectPaths(obj: object, leafsOnly: boolean = false, parentKey?: string): Array<string> {
        let paths: Array<string> = [];

        Object
            .entries(obj)
            .forEach(([key, value]: [string, unknown]) => {
                let path: string = "";

                if (parentKey) {
                    path = parentKey + ".";
                }

                path += key;

                if (typeof (value) === "object" && !Array.isArray(value)) {
                    // recurse
                    if (!leafsOnly) {
                        paths.push(path);
                    }
                    paths.push(...ObjectUtils.getObjectPaths(value as object, leafsOnly, path));
                } else {
                    paths.push(path);
                }
            });

        return paths;
    }

    static hasPath(rawPath: string, object: object): boolean {
        let pathChunks = rawPath.split("."),
            ref: object = object,
            hasPath: boolean = true;

        while (pathChunks.length > 0) {
            let prop: string | undefined = pathChunks.shift();
            if (!prop) break;

            if (Object.hasOwn(ref, prop)) {
                ref = ref[prop as keyof object];
            } else {
                hasPath = false;
                break;
            }
        }

        return hasPath;
    }

    static setToPath(rawPath: string, object: object, value: unknown): void {
        let pathChunks = rawPath.split("."),
            ref: object = object;

        let finalPropName: string = pathChunks.pop() as string; // remove the last entry
        while (pathChunks.length > 0) {
            let prop: string | undefined = pathChunks.shift();
            if (!prop) break;

            if (Object.hasOwn(ref, prop)) {
                ref = ref[prop as keyof object];
            } else {
                Object.defineProperty(ref, prop, {
                    value: {},
                    enumerable: true
                });
                ref = ref[prop as keyof object];
            }
        }

        if (value === undefined) {
            delete ref[finalPropName as keyof object];
        } else {
            Object.defineProperty(ref, finalPropName, { value, enumerable: true, configurable: true });
        }
    }

    static getFromPath(path: Array<string>, object: object): unknown {
        let ref: object = object,
            pathRef: Array<string> = ([] as Array<string>).concat(path), // not to alter the original path
            value: unknown;

        while (pathRef.length > 0 && ref !== undefined) {
            let prop: string | undefined = pathRef.shift();
            if (pathRef.length === 0) {
                value = ref[prop as keyof object];
                break;
            } else {
                if (!prop) break;
                ref = ref[prop as keyof object];
            }
        }

        return value;
    }

    static getFromStringPath(path: string, object: object): unknown {
        return ObjectUtils.getFromPath(path.split("."), object);
    }

    //=========================================================================
    static cloneObj<T extends object>(object: T): T {
        if (typeof (structuredClone) === 'function') {
            return structuredClone(object);
        } else {
            // Fallback for nodejs workers that don't yet have access to structuredClone.
            return JSON.parse(JSON.stringify(object));
        }
    }

    //=========================================================================
    static mapToObject(map: Map<string, object>): object {
        let obj: Record<string, object> = {};

        map.forEach((value: object, key: string) => {
            if (typeof (value) === "object" && value.constructor.name === "Map") {
                value = this.mapToObject(value as Map<string, object>);
            }

            obj[key] = value;
        });

        return obj;
    }

    //=========================================================================
    static mergeObjects<T>(source: T | undefined, override: T | undefined): T | undefined {
        let results: T | undefined;

        if (source !== undefined || override !== undefined) {
            results = Object.assign({}, source, override);
        }

        return results;
    }

    //=========================================================================
    /**
     * Freezes the object itself, and recursively freezes all object values that is encounters. A TypedArray or a
     * DataView with elements will cause a TypeError, so this method silently skips those values that cannot be frozen.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
     */
    static deepFreeze<T>(obj: {}): Readonly<T> {
        Object.values(obj)
            .forEach((propVal: unknown) => {
                if (typeof (propVal) === "object" && !ObjectUtils.isTypedArray(propVal)) {
                    this.deepFreeze(propVal as object);
                    Object.freeze(propVal);
                }
            });

        if (ObjectUtils.isTypedArray(obj)) {
            return obj as Readonly<T>;
        }

        return Object.freeze(obj as T);
    }

    //=========================================================================
    /**
     * Asserts whether or not the passed object is an instance of a TypedArray.
     * 
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/TypedArray#typedarray_objects
     */
    static isTypedArray(obj: any) {
        // This was pulled from
        // https://stackoverflow.com/questions/15251879/how-to-check-if-a-variable-is-a-typed-array-in-javascript

        const TypedArray = Object.getPrototypeOf(Uint8Array);
        return Boolean(obj instanceof TypedArray);
    }


    static isObject(item: any): boolean {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    /**
     * ### Deep merge two objects. 
     * - If the second object has a property that the first object does not, it will be added to the first object.
     * - If the second object has a property that the first object does, the property will be merged recursively.
     * - If the second object has a property that is a Map, the entries in the maps will be merged together in a new Map..
     * - If the second object has a property that is an array, the arrays will be concatenated or overwritten based on `mergeArrays`.
     * 
     * @param first The first object to merge.
     * @param second The second object to merge.
     * @param mergeArrays If true, arrays will be merged together. If false, the second array will overwrite the first.
     * @returns The merged object.
     * 
     */
    static deepMergeObjects<T extends { [key: string]: any }>(first: T | undefined, second: T | undefined, mergeArrays: boolean = false): T | undefined {
        let output: any = undefined;

        if (first === undefined) {
            output = second;
        } else if (second === undefined) {
            output = first;
        } else {
            if (Array.isArray(first) && Array.isArray(second) && mergeArrays) {
                output = mergeArrays ? first.concat(second) : second.slice();
            } else if (first instanceof Map && second instanceof Map) {
                output = new Map([...Array.from(first.entries()), ...Array.from(second.entries())]);
            } else {
                output = Object.assign({}, first);
                if (ObjectUtils.isObject(first) && ObjectUtils.isObject(second)) {
                    Object.keys(second).forEach(key => {
                        if (ObjectUtils.isObject(second[key])) {
                            if (!(key in first))
                                Object.assign(output, { [key]: second[key] });
                            else
                                output[key] = ObjectUtils.deepMergeObjects(first[key], second[key], mergeArrays);
                        } else if (Array.isArray(second[key]) && mergeArrays) {
                            const firstArr: Array<any> = Array.isArray(first?.[key]) ? first[key] : [];
                            output[key] = firstArr.concat(second[key]);
                        } else if (second[key] instanceof Map) {
                            const firstMap: Map<any, any> = (first?.[key] instanceof Map) ? first[key] : new Map();
                            output = new Map<any, any>([...firstMap.entries(), ...second[key].entries()]);
                        } else {
                            Object.assign(output, { [key]: second[key] });
                        }
                    });
                }

            }
        }
        return output;
    }

    static compareArray<T>(ar1: Array<T>, ar2: Array<T>): boolean {
        return ar1.length === ar2.length && ar1.every((value, index) => value === ar2[index]);
    }

    static pruneBlankValues(obj: object): object {
        let pruned: object = Object.assign({}, obj);

        Object
            .entries(obj)
            .forEach(([key, value]: [string, unknown]) => {
                if (value === null || value === undefined || value === "") {
                    delete pruned[key as keyof object];
                }
            });

        return pruned;
    }

    /**
     * #### Check if a value exists in an array OR if provided object is not an array, check if the provided object equals to value.
     * 
     * @param obj The object to check.
     * @param value The value to check for.
     */
    static existsIn(obj: Array<string> | string | number | boolean | undefined, value: string | number | boolean): boolean {
        if (obj === undefined || obj === null) return false;

        if (Array.isArray(obj)) {
            return obj.includes(value as string);
        } else {
            return obj === value;
        }
    }
}
