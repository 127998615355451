import { IEntitySchema } from '../../SchemaEntityUtils';
import {
    SchemaPath,
    ValidationExtraData,
    ValidationResponse,
    ValidationRule,
} from '../SchemaValidationTypes';
import { TypeValidationRule } from './TypeValidationRule';

export class FeatureRefValidationRule extends ValidationRule {
    async exec(
        value: unknown,
        schema: IEntitySchema,
        path: SchemaPath,
        extraData?: ValidationExtraData | undefined
    ): Promise<ValidationResponse> {
        const response: ValidationResponse = { success: true };
        const isRef = path.at(-1) === 'featureRef';

        if (!value && isRef) {
            response.success = false;
            response.error = new Error('Please enter an feature ref.');

            return response;
        }

        const feature = await this.getFeature(value as string, extraData!);
        if (isRef && feature) {
            if (!this.isUnique(value as string, extraData!)) {
                response.success = false;
                response.error = new Error('This feature is already in use.');
            }
        }
        if (!feature) {
            response.success = false;
            response.error = new Error('Feature not found.');
        }

        if (response.success && !isRef) {
            return new TypeValidationRule().exec(value, {
                type: schema!.type,
            });
        }

        return response;
    }

    isUnique(value: string, extraData: ValidationExtraData) {
        const { refList } = extraData;
        return !refList?.find(
            (ref) => ref.featureRef.toLowerCase() === value.toLowerCase()
        );
    }

    async getFeature(ref: string, extraData: ValidationExtraData) {
        const { catalogVersionId } = extraData;

        let cicResponse = await CiCAPI.authoring.getCatalogFeatureDefs(catalogVersionId!);

        if (cicResponse.success) {
            return cicResponse.result?.find(
                (feature: ICatalogFeatureDef) =>
                    feature.code.toLowerCase() === ref.toLowerCase()
            );
        }

        return null;
    }
}
