import { ObjectUtils } from "./ObjectUtils";
import { json2csv } from 'json-2-csv/src/converter';

type ExportOptions = {
    unwindKeys?: string[];
    keys?: string[];
    schema?: any;
}

export class StringUtils {
    static capitalize(s: string): string {
        return s[0].toUpperCase() + s.slice(1);
    }

    static textMatchesWithPattern(text: string, pattern: string): boolean {
        if (!text || !pattern) {
            return false;
        }

        const lastIdx: number = pattern.length - 1;
        if (pattern[0] === '*' && pattern[lastIdx] === '*') {
            return text.indexOf(pattern.substring(1, lastIdx)) >= 0;
        } else if (pattern[0] === '*') {
            return text.endsWith(pattern.substring(1, lastIdx));
        } else if (pattern[lastIdx] === '*') {
            return text.startsWith(pattern.substring(0, lastIdx));
        }

        return text === pattern;
    }

    static generateExportString(entities: Array<any>, format: "csv" | "json" = "json", options?: ExportOptions): string {
        let data = '';

        if (format === "json") {
            if (options?.keys && options.keys.length > 0) {
                entities = entities.map(entity => {
                    return Object.fromEntries((options.keys ?? []).map(key => [key, ObjectUtils.getFromPath(key.split("."), entity)]));
                });
            }
            data = JSON.stringify(entities);
        } else {
            data = json2csv(entities, {
                ...options,
                emptyFieldValue: '',
                delimiter: {
                    eol: '\r\n'
                }
            });
        }

        return data;
    }
}
