import { IEntitySchema } from "../../SchemaEntityUtils";
import {
    SchemaPath,
    ValidationExtraData,
    ValidationResponse,
    ValidationRule,
} from "../SchemaValidationTypes";
import { TypeValidationRule } from "./TypeValidationRule";

export class MaterialRefValidationRule extends ValidationRule {
    async exec(
        value: unknown,
        schema: IEntitySchema,
        path: SchemaPath,
        extraData?: ValidationExtraData
    ): Promise<ValidationResponse> {
        const response: ValidationResponse = { success: true };
        const isRef = path.at(-1) === "materialRef";

        //@ts-ignore
        const ref = isRef ? value : extraData?.parentEntity?.itemRef;

        if (!ref && ref) {
            response.success = false;
            response.error = new Error("Please enter a material ref");

            return response;
        }

        const material = await this.getMaterial(ref as string, extraData!);
        if (!material) {
            response.success = false;
            response.error = new Error("Material not found");
        }

        if (response.success && !isRef) {
            return new TypeValidationRule().exec(value, {
                type: schema!.type,
            });
        }
        return response;
    }

    async getMaterial(ref: string, extraData: ValidationExtraData) {
        const { catalogVersionId } = extraData;
        const cicResponse = await CiCAPI.authoring.getCatalogMaterialDefs(
            catalogVersionId!
        );

        if (cicResponse.success) {
            return cicResponse.result?.find(
                (mat: IMaterialDef) =>
                    mat.code.toLowerCase() === ref.toLowerCase()
            );
        }

        return null;
    }
}
