import { Operation, diff } from "json-diff-ts";
import { EntityType, LiveEditService, SchemaEntityUtils } from "../..";
import { getCatalogEntity } from "../../utils/EntityUtils";
import {
    AuthoringStateMgr,
    AuthoringStateMgrEvent,
    State,
} from "./AuthoringStateMgr";
import { Entity, EntityKey } from "./AuthoringStateMgr.types";
import { CommonUtils } from "@cic/utils/src/CommonUtils";

export class EntityMgr {
    private authoringMgr: AuthoringStateMgr;
    private state: State;
    private entities: State["entities"];

    constructor(authoringMgr: AuthoringStateMgr) {
        this.authoringMgr = authoringMgr;
        this.state = this.authoringMgr.state;
        this.entities = this.state.entities;
    }

    public async fetchEntityRef(
        type: EntityType,
        ref: string,
        catalogVersionId: string
    ) {
        let catalogId = catalogVersionId;
        let entityCode = ref;

        if (entityCode.includes(".")) {
            const [catalogVersionIdFromRef, code] =
                CommonUtils.splitEntityId(ref);
            catalogId = catalogVersionIdFromRef as string;
            entityCode = code;
        }

        return this.fetchEntity(type, entityCode, catalogId);
    }

    public async fetchEntity(
        type: EntityType,
        code: string,
        catalogVersionId: string,
        rootCatalogId?: string,
        silent?: boolean
    ) {
        this.authoringMgr.catalogs.openCatalog(catalogVersionId);
        const entityTypeState =
            this.entities[catalogVersionId][type.toLowerCase() as EntityKey];

        let entity = entityTypeState.current[code];
        await this.authoringMgr.schemas.fetchSchema(type);
        if (!entity) {
            if (type === EntityType.FEATURES && code.includes(":")) {
                //special case for feature refs since they don't have a code
                code = code.split(":").at(-1) || code;
            }
            entity = (await getCatalogEntity(
                type,
                code,
                catalogVersionId,
                rootCatalogId
            )) as Entity;

            if (entity) {
                //@ts-ignore
                if (entity.catalog?.versionId) {
                    //@ts-ignore
                    catalogVersionId = entity.catalog.versionId;
                }

                const schema = this.authoringMgr.schemas.getSchema(
                    type,
                    entity
                );

                const parsedEntity = SchemaEntityUtils.fromEntityInstance(
                    entity,
                    schema
                );
                if (!parsedEntity.code && parsedEntity.featureRef) {
                    parsedEntity.code = parsedEntity.featureRef.split(":")[1];
                }

                this.setEntity(
                    catalogVersionId,
                    type,
                    parsedEntity,
                    false,
                    undefined,
                    silent
                );
            }
        }

        return {
            entity: entityTypeState.current[code],
            catalogVersionId,
        };
    }

    public getCurrentEntity(
        catalogVersionId: string,
        type: EntityType,
        code: string
    ) {
        if (this.entities[catalogVersionId]) {
            return this.entities[catalogVersionId][
                type.toLowerCase() as EntityKey
            ].current[code];
        }
    }

    public async exists(type: EntityType, code: string) {
        const { entity } = await this.fetchEntity(
            type,
            code,
            this.state.currentCatalog!.id,
            undefined,
            true
        );

        return !!entity;
    }

    public setEntity(
        catalogVersionId?: string,
        entityType?: EntityType,
        entity?: Entity,
        overrideOriginal?: boolean,
        original?: Entity,
        silent?: boolean
    ) {
        if (!catalogVersionId || !entityType || !entity) {
            return;
        }

        const entityKey = entityType.toLowerCase() as EntityKey;

        this._setEntity(catalogVersionId, entityKey, entity, overrideOriginal);

        if (overrideOriginal && original) {
            this._setOriginalEntity(
                catalogVersionId,
                entityKey,
                original,
                overrideOriginal
            );
        }

        this.authoringMgr.pendingChanges.updateDiffReport(
            catalogVersionId,
            entityType,
            entity.code
        );

        delete this.entities[catalogVersionId][entityKey].errors[entity.code];

        if (!silent) {
            this.authoringMgr.notifyChange(undefined, {
                entityType: entityType
            });
        }

        LiveEditService.updateEntity(
            catalogVersionId,
            entityType,
            this.entities[catalogVersionId][entityKey].current[entity.code]
        );

        return entity;
    }

    public unsetEntity(
        catalogVersionId: string,
        type: EntityType,
        code: string
    ) {
        try {
            const state =
                this.state.entities[catalogVersionId][
                    type.toLowerCase() as EntityKey
                ];

            delete state.current[code];
            delete state.original[code];
            delete state.diffReport[code];

            this.authoringMgr.notifyChange(undefined, {
                id: code,
                catalogVersionId,
                entityType: type,
            });
        } catch (e) {
            console.warn(e);
        }
    }

    //TODO => move the live edit stuff?
    public updateEntityProperty(
        catalogVersionId: string,
        type: EntityType,
        path: (string | number)[],
        code: string,
        value: unknown,
        schemaType?: string,
        useLiveEdit?: boolean
    ) {
        const entityKey = type.toLowerCase() as EntityKey;
        let entity =
            this.state.entities[catalogVersionId][entityKey].current[code];

        let performLiveEditUpdate = useLiveEdit ?? true;

        if (!entity) {
            if (type === EntityType.FEATURES) {
                //@ts-ignore
                entity = this._setEntity(catalogVersionId, type.toLowerCase(), {
                    featureRef: code,
                });
            } else {
                console.warn(
                    "Trying to update a nonexistant entity",
                    arguments
                );
                return;
            }
        }

        this.state.entities[catalogVersionId][entityKey].current[code] =
            SchemaEntityUtils.updateProperty(entity, path, value, schemaType);

        const pendingChanges =
            this.authoringMgr.pendingChanges.getPendingChanges(
                catalogVersionId,
                type,
                code
            );

        pendingChanges.forEach((change) => {
            if (change.new && change.current?.code === code) {
                performLiveEditUpdate = false;
            }
        });

        this.state.entities[catalogVersionId].liveEditUpdate = {
            name: performLiveEditUpdate === true ? type : "",
            code: performLiveEditUpdate === true ? code : "",
        };

        this.authoringMgr.pendingChanges.updateDiffReport(
            catalogVersionId,
            type,
            code
        );

        LiveEditService.updateEntity(
            catalogVersionId,
            type,
            this.state.entities[catalogVersionId][entityKey].current[code]
        );

        this.authoringMgr.notifyChange(undefined, {
            id: entity.code,
            catalogVersionId,
            entityType: type,
        });
    }

    public deleteEntityProperty(
        catalogVersionId: string,
        type: EntityType,
        path: (string | number)[],
        code: string,
        dataType?: string
    ) {
        const entityKey = type.toLowerCase() as EntityKey;
        const entity =
            this.state.entities[catalogVersionId][entityKey].current[code];

        if (!entity) {
            console.warn("Trying to update a nonexistant entity", arguments);
            return;
        }

        this.state.entities[catalogVersionId][entityKey].current[code] =
            SchemaEntityUtils.deleteProperty(entity, path, dataType);

        this.authoringMgr.pendingChanges.updateDiffReport(
            catalogVersionId,
            type,
            code
        );
        this.authoringMgr.notifyChange(undefined, {
            id: entity.code,
            catalogVersionId,
            entityType: type,
        });
    }

    public createEntity(
        type: EntityType,
        code: string,
        catalogVersionId?: string,
        body?: Partial<Entity>,
        silent?: boolean
    ) {
        const versionId = catalogVersionId || this.state.currentCatalog!.id;
        this.authoringMgr.catalogs.openCatalog(versionId);

        const newEntity = { code, ...body };

        const report =
            this.state.entities[versionId][type.toLowerCase() as EntityKey]
                .diffReport;
        report[code] = diff(undefined, newEntity);
        this._setEntity(
            versionId,
            type.toLowerCase() as EntityKey,
            newEntity,
            false,
            true
        );
        if (!silent) {
            this.authoringMgr.notifyChange();

            if (type === EntityType.ITEMS) {
                this.authoringMgr.notifyChange(
                    AuthoringStateMgrEvent.ItemAdded
                );
            }
        }
    }

    public removeEntity(
        catalogVersionId: string,
        type: EntityType,
        code: string
    ) {
        this.authoringMgr.catalogs.openCatalog(this.state.currentCatalog!.id);

        const report =
            this.state.entities[catalogVersionId][
                type.toLowerCase() as EntityKey
            ].diffReport;

        report[code] = [
            {
                type: Operation.REMOVE,
                key: code,
            },
        ];

        delete this.state.entities[catalogVersionId][
            type.toLowerCase() as EntityKey
        ].current[code];

        this.authoringMgr.notifyChange();
    }
    private _setEntity(
        catalogVersionId: string,
        entityKey: EntityKey,
        entity: Entity,
        overrideOriginal?: boolean,
        skipSettingOriginal?: boolean
    ) {
        this.authoringMgr.catalogs.openCatalog(catalogVersionId);
        if (!skipSettingOriginal) {
            this._setOriginalEntity(
                catalogVersionId,
                entityKey,
                entity,
                overrideOriginal
            );
        }
        this.state.entities[catalogVersionId][entityKey].current[
            entity.code || (entity as ICatalogFeatureDef).featureRef!
        ] = entity;

        return entity;
    }

    public getEntity(catalogVersionId: string, type: EntityType, code: string) {
        const catalogState = this.entities[catalogVersionId];
        const entityState =
            catalogState && catalogState[type.toLowerCase() as EntityKey];

        return {
            original: entityState?.original[code],
            current: entityState?.current[code],
            diffReport: entityState?.diffReport[code],
        };
    }

    public async setEntities(
        catalogVersionId: string,
        entities: Entity[] | null,
        type: EntityType,
        originalEntities: Entity[],
        ignoreRemoveOperations?: boolean,
        skipSettingOriginal?: boolean
    ) {
        if (!entities) return;

        this.authoringMgr.catalogs.openCatalog(catalogVersionId);

        await this.authoringMgr.schemas.fetchSchema(type);

        const modifiedEntities: Entity[] = [];
        const entityKey = type.toLowerCase() as EntityKey;
        const report = this.state.entities[catalogVersionId][entityKey].diffReport;

        const parsedOriginaleEntities = originalEntities.map((e) => {
            const schema = this.authoringMgr.schemas.getSchema(type, e);
            return SchemaEntityUtils.fromEntityInstance(e, schema);
        });

        parsedOriginaleEntities.forEach((e: Entity) =>
            this._setOriginalEntity(catalogVersionId, entityKey, e)
        );

        entities.forEach((entity) => {
            const schema = this.authoringMgr.schemas.getSchema(type, entity);
            const originalEntity = originalEntities.find(
                (o) => o.code === entity.code
            );

            const newEntity = this.authoringMgr.pendingChanges.applyEntityDiff(
                entity,
                report,
                originalEntity,
                ignoreRemoveOperations
            );

            if (newEntity) {
                const entityDef = SchemaEntityUtils.fromEntityInstance(
                    newEntity,
                    schema?.items ?? schema
                );

                const changes = diff(
                    this.state.entities[catalogVersionId][entityKey].original[
                        entity.code
                    ] || originalEntity,
                    entityDef
                );
                if (changes.length > 0) {
                    //dont mark the entity as updated if it still has the same values
                    report[entity.code] = changes;
                    modifiedEntities.push(entityDef);
                    this._setEntity(
                        catalogVersionId,
                        entityKey,
                        entityDef,
                        undefined,
                        skipSettingOriginal
                    );
                }
            }
        });

        this.authoringMgr.notifyChange(undefined, {
            id: `${catalogVersionId}.entities-type.${type}`,
            catalogVersionId,
            entityType: type,
            updatedCodes: modifiedEntities.map((e) => e.code),
        });

        await LiveEditService.updateEntities(
            catalogVersionId,
            type,
            modifiedEntities
        );

        window.postMessage({
            type: "live-edit-refresh-entity",
            entityType: type,
        });
    }

    public setEntityError(
        catalogVersionId: string,
        entityType: EntityType,
        entity: Entity,
        error: Error
    ) {
        if (!entity) {
            return;
        }

        this.entities[catalogVersionId][
            entityType.toLowerCase() as EntityKey
        ].errors[entity.code] = error;
        this.authoringMgr.notifyChange();
    }

    public getEntityError(
        catalogVersionId: string,
        type: EntityType,
        code: string
    ) {
        if (!this.entities[catalogVersionId]) return null;

        const errors =
            this.entities[catalogVersionId][type?.toLowerCase() as EntityKey]
                .errors;

        return errors && errors[code];
    }

    private _setOriginalEntity(
        catalogVersionId: string,
        entityKey: EntityKey,
        entity: Entity,
        overrideOriginal?: boolean
    ) {
        const original =
            this.state.entities[catalogVersionId][entityKey].original;

        if (!original[entity.code] || overrideOriginal) {
            original[entity.code] = JSON.parse(JSON.stringify(entity));
        }
    }
}
