export enum ValidationRules {
    TYPE,
    ATTRIBUTE,
    FEATURE_REF,
    RESTRICTION_REF,
    MATERIAL_REF,
    POS_ORI_SCALE,
    MCODE,
    ITEM_REF
};

export default {
    rules: [
        {
            path: "^(?:(?!attributeOverrides).)*attributeRef$",
            rule: ValidationRules.ATTRIBUTE
        },
        {
            path: "^(?:(?!attributeOverrides).)*attributes/.*/value$",
            rule: ValidationRules.ATTRIBUTE
        },
        {
            path: "featureRef$",
            rule: ValidationRules.FEATURE_REF
        },
        {
            path: "scale|position|orientation",
            rule: ValidationRules.POS_ORI_SCALE
        },
        {
            path: "mCode",
            rule: ValidationRules.MCODE
        },
        {
            path:"itemRef$",
            rule: ValidationRules.ITEM_REF
        }
    ]
};
