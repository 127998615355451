class UIToolkitUtils {

    static findContainerDivByClassName(node: Element | null, className: string): Element | null {
        if (!node) return null;

        let foundNode: Element | null = null;

        if ((node as HTMLElement).classList.contains(className)) {
            foundNode = node;
        }

        if (!foundNode) {
            foundNode = UIToolkitUtils.findContainerDivByClassName(node.parentElement, "doc-selector-entry");
        }

        return foundNode;
    }


    static convertItemVariantToClientItem(itemVariant: IItemVariant): IClientItem {
        let instanceExtras: Record<string, unknown> = {};

        if (itemVariant.position) {
            instanceExtras.position = itemVariant.position;
        }

        if (itemVariant.orientation) {
            instanceExtras.orientation = itemVariant.orientation;
        }

        if (itemVariant.selected) {
            instanceExtras.selected = itemVariant.selected;
        }

        return {
            itemId: itemVariant.id,
            instanceId: itemVariant.uuid,
            configurationState: itemVariant.configurationState,
            instanceExtras
        };
    }

    static resetClientItemPropertiesInItemVariant(destinationItemVariant: IItemVariant, sourceClientItem: IClientItem): IItemVariant {
        let extras: Record<string, unknown> | undefined = sourceClientItem.instanceExtras;

        if (extras && extras.selected) {
            destinationItemVariant.selected = extras.selected as boolean;
        }

        if (sourceClientItem.position) {
            destinationItemVariant.position = sourceClientItem.position;
        }

        if (sourceClientItem.orientation) {
            destinationItemVariant.orientation = sourceClientItem.orientation;
        }

        destinationItemVariant.uuid = sourceClientItem.instanceId;

        return destinationItemVariant;
    }

    static async getItemVariants(clientItems: Array<IClientItem>, apiHandle?: ICiCAPI, options?: IOptionsGetItemVariant): Promise<Array<IItemVariant>> {
        let uniqueVariants: Map<string, IItemVariant> = new Map(),
            promises: Array<Promise<unknown>>,
            results: Array<IItemVariant>;

        promises = clientItems.map(async (clientItem: IClientItem) => {
            if (clientItem.itemId) {
                let key: string = this._getItemVariantKey(clientItem),
                    response: IAPIResponseWithDetails<IItemVariant, Array<IConfigurationChange>>;

                if (!uniqueVariants.has(key)) {
                    response = await this.fetchItemVariant(clientItem, apiHandle, options);
                    if (response.success && response.result) {
                        uniqueVariants.set(key, response.result);
                    }
                }
            }
        });

        await Promise.all(promises);

        results = clientItems
            .map((clientItem: IClientItem) => {
                let key: string = this._getItemVariantKey(clientItem),
                    fetchedVariant: IItemVariant | undefined;

                if (uniqueVariants.get(key)) {
                    fetchedVariant = Object.assign({}, uniqueVariants.get(key));
                    this.resetClientItemPropertiesInItemVariant(fetchedVariant, clientItem);
                }

                return fetchedVariant;
            })
            .filter((itemVariant: IItemVariant | undefined) => itemVariant) as Array<IItemVariant>;

        return results;
    }

    static async getItemVariantById(itemId: string, configurationState?: IConfigurationState, apiHandle?: ICiCAPI, options?: IOptionsGetItemVariant): Promise<IItemVariant | undefined> {
        return this.getItemVariant({ itemId, configurationState }, apiHandle, options);
    }

    static async getItemVariant(clientItem: IClientItem, apiHandle?: ICiCAPI, options?: IOptionsGetItemVariant): Promise<IItemVariant | undefined> {
        let result: IItemVariant | undefined,
            response: IAPIResponseWithDetails<IItemVariant, Array<IConfigurationChange>>;

        if (clientItem.itemId) {
            response = await this.fetchItemVariant(clientItem, apiHandle, options);
            if (response.success && response.result) {
                result = response.result;
                this.resetClientItemPropertiesInItemVariant(result, clientItem);
            }
        }

        return result;
    }

    static async fetchItemVariantById(itemId: string, configurationState?: IConfigurationState, apiHandle?: ICiCAPI, options?: IOptionsGetItemVariant): Promise<IAPIResponseWithDetails<IItemVariant, Array<IConfigurationChange>>> {
        return this.fetchItemVariant({ itemId, configurationState }, apiHandle, options);
    }

    static async fetchItemVariant(clientItem: IClientItem, apiHandle?: ICiCAPI, options?: IOptionsGetItemVariant): Promise<IAPIResponseWithDetails<IItemVariant, Array<IConfigurationChange>>> {
        let handle: ICiCAPI = apiHandle ?? CiCAPI
        if (!clientItem.itemId) throw new Error("Cannot fetch clientItem without an ID");
        return handle.content.getItemVariant(clientItem.itemId, clientItem.configurationState, options);
    }

    static configurationStatesToCacheKey(configurationState?: IConfigurationState): string {
        let cacheKey: string = "",
            featureStates: Array<IFeatureState> = ([] as Array<IFeatureState>).concat(configurationState ?? []); // de-ref original list

        if (featureStates && featureStates.length > 0) {
            Array.from(featureStates)
                .sort(this._sortFeatureStates)
                .forEach((featureState: IFeatureState) => {
                    if (featureState.source && featureState.source !== "default" && featureState.source !== undefined) {
                        cacheKey += `${featureState.featureId}=${featureState.optionId}${(featureState.value === undefined ? "" : "=" + featureState.value)};`;
                    }
                });
        }

        return cacheKey;
    }

    private static _getItemVariantKey(clientItem: IClientItem): string {
        return clientItem.itemId + this.configurationStatesToCacheKey(clientItem.configurationState);
    }

    private static _sortFeatureStates(stateB: IFeatureState, stateA: IFeatureState): number {
        let valueA: string = `${stateA.featureId}`,
            valueB: string = `${stateB.featureId}`,
            rc: number = 0;

        if (valueA < valueB) {
            rc = -1;
        } else if (valueA > valueB) {
            rc = 1;
        }
        return rc;
    }
}

export default UIToolkitUtils;