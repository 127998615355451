import { EntityKey } from "..";
import { areAllValuesNullOrUndefined } from "../../utils/CommonUtils";
import {
    AuthoringStateMgr,
    AuthoringStateMgrEvent,
    EntitiesState,
    State,
} from "./AuthoringStateMgr";

export class CatalogMgr {
    private authoringMgr: AuthoringStateMgr;
    private state: State;

    constructor(authoringMgr: AuthoringStateMgr) {
        this.authoringMgr = authoringMgr;
        this.state = this.authoringMgr.state;
    }

    public openCatalog(catalogVersionId: string) {
        if (!this.state.entities[catalogVersionId]) {
            const types = [
                "items",
                "features",
                "attributes",
                "itemgroups",
                "materials",
                "options",
                "restrictions",
                "dependencies",
            ];

            const openedCatalog: Partial<State["entities"][""]> = {
                liveEditUpdate: { name: "", code: "" },
            };

            types.forEach((type) => {
                openedCatalog[type as EntityKey] = {
                    current: {},
                    original: {},
                    diffReport: {},
                    errors: {},
                };
            });
            this.state.entities[catalogVersionId] =
                openedCatalog as State["entities"][""];
            this.authoringMgr.notifyChange();
        }
    }

    public getCatalogRef(catalogVersionId: string) {
        const currentCatalogVersion = this.state.currentCatalogVersion;

        if (
            !currentCatalogVersion ||
            !currentCatalogVersion.resolvedDependencies
        ) {
            return null;
        }

        const [ref] =
            Object.entries(currentCatalogVersion.resolvedDependencies).find(
                ([, value]) => String(value.id) === catalogVersionId
            ) || [];

        return ref ? ref : null;
    }

    public getCatalogVersionFromRef(catalogRef: string) {
        if (!this.state.currentCatalogVersion || !catalogRef) {
            return null;
        }

        const [, catalogVersion] =
            Object.entries(
                this.state.currentCatalogVersion.resolvedDependencies!
            ).find(([key]) => key === catalogRef) || [];

        return catalogVersion ? String(catalogVersion.id) : null;
    }

    public closeEmptyCatalog(catalogVersionId: string) {
        const catalogState = this.state.entities[catalogVersionId];
        let isEmpty = true;

        if (catalogState) {
            Object.keys(catalogState).forEach((key) => {
                const state = catalogState[
                    key as keyof typeof catalogState
                ] as EntitiesState<unknown>;

                if (
                    isEmpty &&
                    !areAllValuesNullOrUndefined(state) &&
                    key !== "liveEditUpdate"
                ) {
                    isEmpty = false;
                }
            });
        }

        if (isEmpty) {
            delete this.state.entities[catalogVersionId];
        }

        this.authoringMgr.notifyChange();
    }

    public setCurrentCatalog(
        catalog: (ICatalog & ICatalogVersion) | null,
        catalogVersion: IVersionDef | null
    ) {
        this.state.currentCatalog = catalog;
        this.state.currentCatalogVersion = catalogVersion;
        this.authoringMgr.notifyChange(
            AuthoringStateMgrEvent.CurrentCatalogChanged
        );
    }

    public updateCurrentCatalogState = async () => {
        if (this.state.currentCatalogVersion) {
            const catalogVersionId = this.state.currentCatalogVersion.id;
            const dependenciesCode = `${catalogVersionId}-dependencies`;

            if (
                catalogVersionId in this.state.entities &&
                dependenciesCode in
                    this.state.entities[catalogVersionId].dependencies.current
            ) {
                const current =
                    this.state.entities[catalogVersionId].dependencies.current[
                        dependenciesCode
                    ];
                this.state.currentCatalogVersion.catalogDependencies = current.entity;

                const resp = await CiCAPI.authoring.getResolvedDependencies(
                    catalogVersionId.toString(),
                    current.entity ?? {}
                );
                if (resp.success) {
                    this.state.currentCatalogVersion.resolvedDependencies = resp.result;
                }

                this.authoringMgr.notifyChange(
                    AuthoringStateMgrEvent.CurrentCatalogChanged
                );
            }
        }
    };
}
